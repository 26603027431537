/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-da4qz2 --style4 bg--bottom --full" name={"intro"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" anim={"6"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 w--600 swpf--uppercase ls--50" content={"<span style='color: white'>Peter Nemec</span>"}>
              </Subtitle>

              <Title className="title-box fs--86 w--500 lh--1" content={"<span style='color: white'>Hassle-Free Electrical Services</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--cColor1 fs--22" href={"#Services-2"} content={"Services"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"intro-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--10 pt--10" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"High Voltage / Low Voltage installations"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1xaz0qc bg--center --parallax pb--80 pt--80" name={"services"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40" columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"services-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Services</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Low Voltage Installations</span>"}>
              </Subtitle>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>— Office buildings<br>— Residential buildings<br>— Commercial spaces<br>— Others</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>High Voltage Installations</span>"}>
              </Subtitle>

              <Text className="text-box mb--40" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>— Office buildings<br>— Residential buildings<br>— Commercial spaces<br>— Others</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"references"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"References"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--3 mt--30" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/126/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/126/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/126/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/126/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/126/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box mt--10" content={"Electrical services"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/126/img-2_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/126/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/126/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/126/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/126/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box mt--10" content={"Electrical services"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/126/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/126/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/126/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/126/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/126/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/126/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box mt--10" content={"Electrical services"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1cof3mw --parallax pb--80 pt--80" name={"contact"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/126/img-2_s=2000x_.jpg_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"<span style='color: white'>Finch Street 24<br>510-851-40X4<br>info@vase-stranky.com</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--18 swpf--uppercase ls--50" content={"Peter Nemec"}>
              </Subtitle>

              <Title className="title-box fs--36 mt--02" content={"Electrical services"}>
              </Title>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}